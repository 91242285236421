import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/0-layout"
import Query from "../components/query"
import SEO from "../components/seo"
import "../scss/inquiry.scss"

/**
 * @property {object} allInquiryJson
 * @property {object} placeHolder
 */

const InquiryPage = () => {
  const data = Query()

  const form = useStaticQuery(graphql`
      query InquiryQuery {
          allInquiryJson {
              edges {
                  node {
                      label
                      require
                      type
                      options
                  }
              }
          }
      }
  `)

  let items = []
  form.allInquiryJson.edges.forEach(i => {
    let html = null

    switch (i.node.type) {
      case "check":
        html = (
          <div>
            <input type={"checkbox"} value={"個人情報の取り扱いについて同意する"} id={"agree"}/>
            &nbsp;個人情報の取り扱いについて同意する
          </div>
        )
        break

      case "email":
        html = <input type={i.node.type} name={"_replyto"} required={i.node.require ? "required" : ``}/>
        break

      case "options":
        let tmp = []

        i.node.options.forEach(j => tmp.push(<option value={j}>{j}</option>))
        html = <select name={i.node.label} required={i.node.require ? "required" : ``}>{tmp}</select>
        break

      case "textarea":
        html = <textarea name={i.node.label} required={"require"}/>
        break

      default:
        html = <input type={i.node.type} name={i.node.label} required={i.node.require ? "required" : ``}/>
    }

    items.push(
      <tr>
        <th>
          <label htmlFor={i.node.label}>
            {i.node.label}
          </label>
        </th>

        <td>
          {i.node.require ? <span>※</span> : ``}
        </td>

        <td className={"input"}>
          {html}
        </td>
      </tr>,
    )
  })

  return (
    <Layout sitePath={"inquiry"} pageTitle={data.allFooterJson.edges[5].node.li}>

      <SEO title={data.site.siteMetadata.form}/>

      <p> 入力いただきました情報はお問い合わせのご対応のために使用いたします。これにあたり、中央海産株式会社からご連絡を取らせていただくことがございます。ご同意いただいた上、ご記入ください。</p>

    <p> 以下のお問合せに必要な情報を入力して、「送信」ボタンをクリックしてください。※印は必須項目となっております。必ずご記入ください。</p>

      <p>※タイヤ保管サービスの業務についてのご質問は、担当の認定倉庫業者様へご連絡ください。担当の認定倉庫業者様の連絡先は、Web 管理システムにログインし、画面上部のメニューの「お問い合わせ」をクリックすると表示されます。</p>

      <form id={"fs-frm"} name={"inquiry"} acceptCharset={"utf-8"} action={"https://formspree.io/"+data.site.siteMetadata.support}
            method={"post"}>
        <fieldset id={"fs-frm-inputs"}>

          <table id={"fs-frm-table"}>
            {items}
          </table>

          <p
            id={"mes"}>以下、弊社の「<a href="/privacy/" style={{color:"blue"}} target="_blank">プライバシーポリシー↗</a>（リンク先）」をご確認いただき、同意していただける場合は「同意する」をチェックの上「送信」をクリックして下さい。</p>

        <p>&nbsp;</p>

        <input type={"hidden"} name={"_subject"} value={"問い合わせメール"} />

          <div className={"title-login"} id={"submit"}>
            <input type={"submit"} value={"送信"} style={{border:"1px solid gray", padding:"10px", textAlign:"center" }} />
          </div>

        <p>&nbsp;</p>

        </fieldset>
      </form>


    </Layout>
  )
}

export default InquiryPage
